
















import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useProduct } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ProductResponseDto } from "@/models/interface/master-product";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SelectProduct extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: String, default: "code" })
  optionLabel!: "code" | "name";

  @Prop({ required: false, type: String, default: "" })
  partCategoryId!: string;

  options: Option<ProductResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = this.buildQuery();
    this.fetchOptions(params);
  }

  get isMapAsCode(): boolean {
    return this.optionLabel === "code";
  }

  @Watch("partCategoryId")
  onChangePartCategory(): void {
    const params = this.buildQuery();
    this.fetchOptions(params);
  }

  fetchOptions(
    params: RequestQueryParamsModel = new RequestQueryParams()
  ): void {
    const { findAll, toOptionsCode, toOptionsName } = useProduct();

    this.loading = true;
    findAll(params)
      .then(({ data }) => {
        if (this.isMapAsCode) {
          this.options = toOptionsCode(data);
        } else {
          this.options = toOptionsName(data);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { searchBy } = useProduct();
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    const { search: defaultQuery } = this.buildQuery();
    const queries: Array<string> = [];

    if (defaultQuery) {
      queries.push(defaultQuery);
    }

    if (val) {
      queries.push(searchBy({ [this.optionLabel]: val }));
    }

    params.search = queries.join(builder.AND);

    this.fetchOptions(params);
  }

  findOption(value: string): Option<ProductResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }

  buildQuery(): RequestQueryParamsModel {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const queries: Array<string> = [];

    if (this.partCategoryId) {
      queries.push(
        builder.push(["category.secureId", this.partCategoryId]).build()
      );
    }

    params.search = queries.join(builder.AND);

    return params;
  }
}
