var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_list_of_product") } },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            model: _vm.form,
            "label-align": "left",
            "label-col": { span: 5 },
            "wrapper-col": { span: 12 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleFind.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_branch"), prop: "branchId" }
                    },
                    [
                      _c("SelectBranch", {
                        on: { "update:meta": _vm.onChangeBranch },
                        model: {
                          value: _vm.form.branchId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "branchId", $$v)
                          },
                          expression: "form.branchId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_warehouse"),
                        prop: "warehouseId"
                      }
                    },
                    [
                      _c("SelectWarehouse", {
                        attrs: { "branch-id": _vm.form.branchId },
                        on: { "update:meta": _vm.onChangeWarehouse },
                        model: {
                          value: _vm.form.warehouseId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "warehouseId", $$v)
                          },
                          expression: "form.warehouseId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_rack"), prop: "rack" } },
                    [
                      _c("SelectRack", {
                        attrs: {
                          "warehouse-id": _vm.form.warehouseId,
                          "value-as": "id"
                        },
                        model: {
                          value: _vm.form.rack,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "rack", $$v)
                          },
                          expression: "form.rack"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_part_category"),
                        prop: "partCategoryId"
                      }
                    },
                    [
                      _c("SelectProductCategory", {
                        on: { "update:meta": _vm.onChangePartCategory },
                        model: {
                          value: _vm.form.partCategoryId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "partCategoryId", $$v)
                          },
                          expression: "form.partCategoryId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_part_number"),
                        prop: "partNumber",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectProduct", {
                        attrs: {
                          "option-label": "code",
                          "part-category-id": _vm.form.partCategoryId
                        },
                        on: { "update:meta": _vm.onChangePart },
                        model: {
                          value: _vm.form.partNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "partNumber", $$v)
                          },
                          expression: "form.partNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_part_name"),
                        prop: "partName",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectProduct", {
                        attrs: {
                          "option-label": "name",
                          "part-category-id": _vm.form.partCategoryId
                        },
                        on: { "update:meta": _vm.onChangePart },
                        model: {
                          value: _vm.form.partName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "partName", $$v)
                          },
                          expression: "form.partName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_brand"),
                        prop: "brand",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("master-product-merk-select", {
                        model: {
                          value: _vm.form.brand,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "brand", $$v)
                          },
                          expression: "form.brand"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleReset } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.find,
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          size: "small",
          "row-key": "id",
          loading: _vm.loading.find,
          "data-source": _vm.dataReport.data,
          columns: _vm.columns,
          pagination: {
            showTotal: function() {
              return _vm.$t("lbl_total_items", {
                total: _vm.dataReport.totalElements
              })
            },
            showSizeChanger: true,
            total: _vm.dataReport.totalElements,
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
            current: _vm.pagination.page,
            defaultPageSize: _vm.pagination.limit
          },
          scroll: { y: 700, x: "calc(100% + 300px)" }
        },
        on: { change: _vm.onTableChange },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("lbl_as_of_date") + ": ") +
                      " " +
                      _vm._s(_vm._f("date")(_vm.now))
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "nullable",
            fn: function(text) {
              return [_vm._v(" " + _vm._s(text || "-") + " ")]
            }
          },
          {
            key: "number",
            fn: function(text) {
              return [_vm._v(" " + _vm._s(_vm._f("qty")(text)) + " ")]
            }
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "mt-2 text-right" },
        [
          _c(
            "a-space",
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading.print },
                  on: { click: _vm.handlePrint }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading.download },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }